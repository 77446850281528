// add media query style
const style = document.createElement('style')
style.innerHTML = `
@media only screen and (min-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media only screen and (max-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 50%;
    max-width: 60%;
    margin-bottom: 48px !important;
  }

  .col-xxl-3 .card {
    width: 60%;
    margin: 0 auto;
    padding: 24px !important;
    border-radius: 4px;
  }
  
  .col-xxl-3 .card .card-p {
    padding: 4px 20px !important;
    margin-top: -49px !important;
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .gap-2-md {
    gap: 2rem !important;
  }

  .m-auto-md {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .w-80-md {
    width: 80% !important;
  }

  .flex-column-reverse-md {
    flex-direction: column-reverse !important;
  }

  .corner-login {
    padding: 1.5rem;
  }

  .function-detail {
    width: 80% !important;
  }

  .justify-content-center-md {
    justify-content: center !important;
  }

  .justify-content-around-md {
    justify-content: space-around !important;
  }

  .w-100-md {
    width: 100% !important;
  }

  .flex-column-md {
    flex-direction: column !important;
  }

  .align-items-center-md {
    align-items: center !important;
  }

  .rotate-90-md {
    transform: rotate(90deg) !important;
  }

  .gap-4-md {
    gap: 4rem !important;
  }

  .d-none-md {
    display: none !important;
  }

  .d-block-md {
    display: block !important;
  }

  .d-flex-md {
    display: flex !important;
  }

  .nav-container {
    padding: 1.5rem;
    border-top: 1px solid #dadada;
    margin-top: 1.2rem;
    gap: 1.5rem !important;
  }

  .footer-top .footer-nav {
    padding-right: 1.5rem;
  }
  .footer-container .footer-bottom {
    margin-left: 1.5rem;
  }

  .footer-right {
    margin-left: 0.375rem;
  }

  footer .footer-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

 @media only screen and (max-width: 744px) {
  .logos img {
    width: 120px !important;
  }
  
  .news-content img {
    width: 100% !important;
  }
  
  img[alt="step"] {
    width: 51px !important;
    height: 51px !important;
  }
  
  img[alt="checkbox"] {
    width: 16px !important;
    height: 16px !important;
  }
  
  .fs-12-sm {
    font-size: 12px !important;
  }
  
  .title {
    top:12.5% !important;
    white-space: nowrap !important;
  }
  
  .customer-container img {
    width: 44px;
    height: 44px;
  }
  
  .detail {
    max-height: 48px !important;
  }
  
  .height-inherit-sm {
    height: inherit !important;
  }
  
  .footer-right {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  
  .footer-bottom-left {
    font-size: 12px !important;
  }
  
  main .carousel-indicators .carousel-control-prev {
    width: 24px !important;
    height: 24px !important;
  }
  
  main .carousel-indicators .carousel-control-next {
    width: 24px !important;
    height: 24px !important;
  }
  
  body {
    font-size: 14px !important;
  }
  
  .line-height-inherit-sm {
    line-height: inherit !important;
   }
  
  .fs-22, .fs-20, .fs-18, .fs-14, .fs-16, .fs-12 {
    font-size: 12px !important;
  }
  
  .fs-28 {
    font-size: 14px !important;
  }
  
  .fs-32 {
    font-size: 16px !important;
  }
  
  .fs-36 {
    font-size: 20px !important;
  }
  
  .fs-42 {
    font-size: 22px !important;
   }

  .col-xxl-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 48px !important;
  }

  .col-xxl-3 .card {
    width: 60%;
    margin: 0 auto;
    padding: 24px !important;
    border-radius: 4px;
  }

  .col-xxl-3 .card .card-p {
    padding: 4px 20px !important;
    margin-top: -51px !important;
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .d-none-sm {
    display: none !important;
  }

  .align-items-center-sm {
    align-items: center !important;
  }

  .w-80-sm {
    width: 80% !important;
  }

  .m-auto-sm {
    margin: auto !important;
  }

  .gap-4-sm {
    gap: 4rem !important;
  }

  .rotate-90-sm {
    transform: rotate(90deg) !important;
  }

  .flex-column-sm {
    flex-direction: column !important;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .top-6-sm {
    top: 6rem !important;
  }

  .banner-container {
    left: 0;
    margin: auto;
    top: 67.25rem !important;
  }

  .qrcode {
    display: none !important;
  }
  
  .download img {
    width: 24px;
    height: 24px;
  }

  .section-container {
    margin-top: 92rem !important;
  }

  .contact-button {
    width: 29rem !important;
  }
  
  .contact-button img {
    width: 24px !important;
    height: 24px !important;
  }

  .intro-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem !important;
  }

  .second-intro {
    flex-direction: column-reverse;
    align-items: center;
  }

  .business-container {
    flex-wrap: wrap;
    gap: 8rem !important;
    margin-top: 8rem !important;
  }

  .awards {
    justify-content: center;
  }

  .footer-container {
    flex-wrap: wrap;
  }

  .footer-top {
    flex-direction: column;
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .footer-nav {
    border: none !important;
    margin-bottom: 12rem;
  }

  .footer-right {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-left: 0!important;
  }
  
  .footer-bottom {
    width: 100%;
    margin-left: 0!important;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

`

document.head.appendChild(style)

// responsive
// change the html font-size base on the window width(initial width: 1440px)

const html = document.documentElement
const width = html.clientWidth

let fontSize = (16 / 1440) * width

if (fontSize > 16) {
  fontSize = 16
}

html.style.fontSize = fontSize + 'px'
